import API from '../modules/api';
import baseActionsFor from './baseActions';
import asyncActionsFor from './asyncActions';
import { userSchema, userListSchema } from './schemas';

// Create Base Actions
const baseActions = baseActionsFor('users', 'user');

// Create Base Async Actions
const asyncActions = asyncActionsFor({
  baseActions,
  resourceName: 'user',
  listName: 'users',
  resourceSchema: userSchema,
  listSchema: userListSchema
});

// Custom Actions and Base Action Overrides
const customActions = {
  addCustomerToList(email) {
    return dispatch => {
      dispatch(baseActions.createStart());

      const promise = API.post(`/api/users/add_customer_to_list`,
                              { email: email });

      promise.then((response) => {
        dispatch(baseActions.createSuccess());
      });

      promise.catch((response) => {
        dispatch(baseActions.createError(response));
        if (response.status === 404) {
          dispatch(displayError(404, response.statusText, true));
        } else {
          dispatch(displayError(500, response.statusText, true));
        }
      });

      return promise;
    };
  },
};

// Merge Actions
const actions = Object.assign(baseActions, asyncActions, customActions);

// Export
export default actions;
