import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

// Components
import { Link } from 'react-router-dom';
import Modal from './Modal';

// Selectors
import { getCityConfig } from '../../selectors';

// Actions
import userActions from '../../actions/userActions';

import styles from './Footer.module.postcss';

class Footer extends PureComponent {
  constructor(props) {
    super(props);
    this.handleMailingListSubmit = this.handleMailingListSubmit.bind(this);
    this.mailingListEmail = React.createRef();
    this.state = {
      showMailingListModal: false,
    };
  }

  handleMailingListSubmit(e) {
    e.preventDefault();
    this.props.addCustomerToList(this.mailingListEmail.current.value).then(() => {
      this.setState({ showMailingListModal: true })
    });
  }

  render() {
    const { cityConfig, isMobile, pathname } = this.props;
    const { showMailingListModal } = this.state;

    const noMobile = pathname ? pathname.includes('/search') : false;
    const currentYear = new Date().getFullYear();

    if (noMobile && isMobile) {
      return false;
    }

    return (
      <footer className={styles.Footer}>
        <div className={styles.Footer_wrapper + " NewSection"}>
          <div className={styles.Footer_section}>
            <nav className={styles.Footer_nav}>
              { !isMobile &&
                <Link
                  to="/support"
                  className={styles.Footer_navLink + " u-noMobileInlineBlock"}
                >
                  Support
                </Link>
              }

              <Link
                to="/blog"
                className={styles.Footer_navLink}
              >
                Blog
              </Link>

              <a href="/privacy-policy" className={styles.Footer_navLink}>Privacy Policy</a>
              <a href="/terms-and-conditions" className={styles.Footer_navLink}>Terms of Service</a>
            </nav>

            { !isMobile &&
              <div className={styles.Footer_finePrint + " u-noMobile"}>
                <p>
                  Powered by <a href="http://www.curbsideprovisions.com">Curbside Provisions</a>&nbsp;
                  <span>&copy; {currentYear} All Rights Reserved</span>
                </p>
              </div>
            }
          </div>

          <div className={styles.Footer_section}>
            <p className={styles.Footer_heading}>Follow us on Social Media</p>
            <div className={styles.Footer_social}>
              <a href={cityConfig.facebook}>
                <img src='/static/images/icons/footer/facebook.svg' alt='Facebook' width='30' height='30'/>
              </a>
              <a href={"https://www.instagram.com/" + cityConfig.instagram}>
                <img src='/static/images/icons/footer/instagram.svg' alt='Instagram' width='30' height='30'/>
              </a>
              <a href={"http://twitter.com/" + cityConfig.twitter}>
                <img src='/static/images/icons/footer/twitter.svg' alt='Twitter' width='30' height='30'/>
              </a>
              <a href="https://open.spotify.com/user/7t6mfs9vqcus550qormce0olk">
                <img src='/static/images/icons/footer/spotify.svg' alt='Spotify' width='30' height='30'/>
              </a>
            </div>
          </div>

          { !isMobile &&
            <div className={styles.Footer_section + " u-noMobile"}>
              <p className={styles.Footer_heading}>Join our mailing list!</p>
              <form className={styles.InlineForm} onSubmit={this.handleMailingListSubmit}>
                <input type="text" ref={this.mailingListEmail} placeholder="Enter email..." required />
                <button className="Button" type="submit">Submit</button>
              </form>
            </div>
          }

          { isMobile &&
            <div className={styles.Footer_section + " u-mobileOnly"}>
              <div className={styles.Footer_finePrint}>
                <p>
                  Powered by <a href="http://www.curbsideprovisions.com">Curbside Provisions</a><br />
                  <span>&copy; {currentYear} All Rights Reserved</span>
                </p>
              </div>
            </div>
          }
        </div>

        { showMailingListModal &&
          <Modal>
            <h4>Subscription Confirmed</h4>
            <p>Your subscription to our list has been confirmed.</p>
            <p>Thank you for subscribing!</p>
            <p>
              {cityConfig.site_title}<br/>
              113 Cherry Street<br/>
              #45647<br/>
              Seattle, WA 98104
            </p>
            <button className="Button" type="button" onClick={() => { this.setState({ showMailingListModal: false }) }}>
              Close
            </button>
          </Modal>
        }
      </footer>
    );
  }
}

Footer.propTypes = {
  history: PropTypes.object,
};

function mapStateToProps(state) {
  return {
    cityConfig: getCityConfig(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    addCustomerToList(email) {
      return dispatch(userActions.addCustomerToList(email));
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Footer);
